import PropTypes from "prop-types";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import StepList from "components/shared/stepList/StepList";

import {
    Container,
    Title,
    Content,
    ImageContainer,
    Image
} from "./Steps.styled";

function Steps({ title, image, items }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Container forwardedAs="section">
            <Content>
                <Title>{title}</Title>
                <StepList items={items} />
            </Content>
            {!mobileView && (
                <ImageContainer>
                    <Image
                        alt={image?.alt ?? "Hoe het werkt"}
                        src={image?.url ?? "/images/steps.jpg"}
                        layout="fill"
                        objectFit="cover"
                        quality={75}
                    />
                </ImageContainer>
            )}
        </Container>
    );
}

Steps.propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        })
    )
};

Steps.defaultProps = {
    title: "Hoe het werkt",
    image: {
        url: "/images/steps.jpg",
        alt: "Hoe het werkt"
    },
    items: [
        {
            title: "Bepaal een waarde",
            description:
                "Jij kiest het saldo op de kaart, dit saldo mag in één keer of in delen worden verzilverd. Je kan ook meerdere kaarten met verschillende waardes bestellen."
        },
        {
            title: "Persoonlijk maken",
            description:
                "Personaliseer jouw cadeau met je eigen boodschap, verpakking of fles wijn. Reken af met een betaalmethode naar keuze."
        },
        {
            title: "Je ontvanger geniet",
            description:
                "Hij/zij mag kiezen uit ruim 210 sauna’s, thermen of wellness centra en genieten van een heerlijke dag ontspannen."
        }
    ]
};

export default Steps;
