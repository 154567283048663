import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import parse from "html-react-parser";

import IconCheck from "components/ui/icons/IconCheckmark";

import { UspTickerStyled, Text } from "./UspTicker.styled";

function UspTicker({ usps, type }) {
    const [currentId, setCurrentId] = useState(0);
    const [tickerChange, setTickerChange] = useState(false);

    useEffect(() => {
        let tickerInterval;
        let delayTimeout;

        if (usps.length > 1) {
            tickerInterval = setInterval(() => {
                setTickerChange(true);
                delayTimeout = setTimeout(() => {
                    setCurrentId(c => (c < usps.length - 1 ? c + 1 : 0));
                    setTickerChange(false);
                }, 400);
            }, 3000);
        } else {
            if (tickerInterval) {
                clearInterval(tickerInterval);
            }

            if (delayTimeout) {
                clearTimeout(delayTimeout);
            }
        }

        return () => {
            clearInterval(tickerInterval);
            clearTimeout(delayTimeout);
        };
    }, [usps]);

    if (usps?.length === 0) {
        return null;
    }

    return (
        <UspTickerStyled type={type}>
            <Text tickerChange={tickerChange} type={type}>
                {type === "smaller" && (
                    <IconCheck size={16} fill="var(--color-gray-300)" />
                )}
                <span>{parse(usps[currentId].title)}</span>
            </Text>
            {usps?.length > 1 && (
                <Text tickerChange={tickerChange} type={type}>
                    {type === "smaller" && (
                        <IconCheck size={16} fill="var(--color-gray-300)" />
                    )}
                    <span>
                        {currentId > usps.length - 2
                            ? parse(usps[0].title)
                            : parse(usps[currentId + 1].title)}
                    </span>
                </Text>
            )}
        </UspTickerStyled>
    );
}

UspTicker.propTypes = {
    usps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string
        })
    ),
    type: PropTypes.oneOf(["default", "inverted", "smaller"])
};

UspTicker.defaultProps = {
    usps: [
        { title: "Te bestellen van € 5,- tot € 150,-" },
        { title: "De 210 mooiste locaties van Nederland" },
        { title: "Voor 17:00 besteld = vandaag verstuurd" },
        { title: "Bedrijven bestellen gemakkelijk op factuur" }
    ],
    type: "default"
};

export default UspTicker;
