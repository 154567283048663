import PropTypes from "prop-types";

function IconCheckmark({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            clipRule="evenodd"
            fillRule="evenodd"
            fill={fill}
            {...rest}
        >
            <path d="M52.6 15.9L27 42 11.6 26.6c-.8-.8-2.2-.8-3 0-.4.4-.6.9-.6 1.5v.1c0 .5.2 1 .6 1.4L27 48l28.5-29.1c.8-.8.8-2.2-.1-3-.8-.7-2-.7-2.8 0z" />
        </svg>
    );
}

IconCheckmark.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconCheckmark.defaultProps = {
    fill: "var(--color-black)",
    size: 26,
    style: { verticalAlign: "middle" }
};

export default IconCheckmark;
