import PropTypes from "prop-types";
import parse from "html-react-parser";

import ArrowLink from "components/ui/arrowLink/ArrowLink";

import { Container, LinkItems, LinkItem, Title } from "./Usps.styled";

function Usps({ items }) {
    return (
        <Container forwardedAs="section">
            <LinkItems>
                {items.map(({ title, link }, index) => (
                    <LinkItem key={`usps-${index}-${title}`}>
                        <Title>{parse(title)}</Title>
                        {link && (
                            <ArrowLink url={link?.url} label={link?.label} />
                        )}
                    </LinkItem>
                ))}
            </LinkItems>
        </Container>
    );
}

Usps.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        })
    )
};

Usps.defaultProps = {
    items: [
        {
            title: "Eindeloos personaliseren",
            link: {
                url: "/saunabon-bestellen/",
                label: "Maak jouw cadeau"
            }
        },
        {
            title: "85+ locaties",
            link: {
                url: "/resorts/",
                label: "Bekijk alle locaties"
            }
        },
        {
            title: "Gratis ingepakt",
            link: {
                url: "/saunabon-bestellen/",
                label: "Of kies een speciale verpakking"
            }
        },
        {
            title: "Waarde van € 5,- tot € 150,-",
            link: {
                url: "/saldocheck/",
                label: "Check jouw saldo"
            }
        }
    ]
};

export default Usps;
