import PropTypes from "prop-types";

import { Item, Logo, Logos } from "./ResellerLogos.styled";

function ResellerLogos({ logos, grayscale, className }) {
    return (
        <Logos className={className}>
            {logos.map((item, index) => (
                <Item key={`sellingpoints-logo-${index}`}>
                    <Logo
                        src={item.url}
                        alt={item.alt}
                        width={145}
                        height={75}
                        loading="lazy"
                        grayscale={grayscale}
                    />
                </Item>
            ))}
        </Logos>
    );
}

ResellerLogos.propTypes = {
    logos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    ),
    grayscale: PropTypes.bool,
    className: PropTypes.string
};

ResellerLogos.defaultProps = {
    logos: [
        {
            url: "/images/logos/sellingpoints/logo-cadeaubon-nl.svg",
            alt: "Logo Cadeaubon"
        },
        {
            url: "/images/logos/sellingpoints/logo-cadeaubonnen-nl.svg",
            alt: "Logo Cadeaubonnen"
        },
        {
            url: "/images/logos/sellingpoints/logo-cadeaukaart-nl.svg",
            alt: "Logo Cadeaukaart"
        },
        {
            url: "/images/logos/sellingpoints/logo-giftcards-nl.svg",
            alt: "Logo Giftcards"
        },
        {
            url: "/images/logos/sellingpoints/logo-bol-com.svg",
            alt: "Logo bol.com"
        },
        {
            url: "/images/logos/sellingpoints/logo-cigo-large.svg",
            alt: "Logo Cigo"
        },
        {
            url: "/images/logos/sellingpoints/logo-jumbo.svg",
            alt: "Logo Jumbo"
        },
        {
            url: "/images/logos/sellingpoints/logo-coop.svg",
            alt: "Logo Coop"
        },
        {
            url: "/images/logos/sellingpoints/logo-primera.svg",
            alt: "Logo Primera"
        }
    ],
    grayscale: false,
    className: ""
};

export default ResellerLogos;
