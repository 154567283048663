import styled from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";

export const Wrapper = styled(BaseContainer)`
    margin: 0 auto 115px;

    ${media.mdUp} {
        margin: 0 auto 120px;
    }
`;
