import { Container, Text, Logos, Item, Logo } from "./Partners.styled";

const items = [
    {
        src: "/images/payment-methods/logo-rabobank.svg",
        alt: "Logo Rabobank",
        width: 80,
        height: 80
    },
    {
        src: "/images/payment-methods/logo-abnamro.svg",
        alt: "Logo ABN Amro",
        width: 115,
        height: 115
    },
    {
        src: "/images/logos/logo-postnl.svg",
        alt: "Logo PostNL",
        width: 45,
        height: 45
    },
    {
        src: "/images/payment-methods/logo-ing.svg",
        alt: "Logo ING bank",
        width: 115,
        height: 115
    }
];

function Partners() {
    return (
        <Container>
            <Text>
                Deze bedrijven hielpen wij aan cadeaukaarten voor hun werknemers
                en/of relaties
            </Text>
            <Logos>
                {items.map(({ src, alt, width, height }) => (
                    <Item key={`logo-${src}`}>
                        <Logo
                            alt={alt}
                            src={src}
                            width={width}
                            height={height}
                            loading="lazy"
                        />
                    </Item>
                ))}
            </Logos>
        </Container>
    );
}

export default Partners;
