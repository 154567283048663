import styled from "styled-components";

import PageTitle from "components/shared/pageTitle/PageTitle";
import BaseContainer from "components/ui/container/Container";
import Button from "components/ui/button/Button";

import media from "constants/media";
import Card from "./card/Card";

export const BackgroundImage = styled.section`
    margin: 0 0 35px;

    background: url(${({ imgMobile }) => imgMobile}) center 90px no-repeat;

    ${media.mdUp} {
        background: url(${({ imgDesktop }) => imgDesktop}) center top no-repeat;
        background-size: auto 450px;
    }

    ${media.lgUp} {
        margin: 0 0 100px;

        background-size: auto;
    }
`;

export const Container = styled(BaseContainer)`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-top: 55px;

    ${media.mdUp} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 180px;
    }

    ${media.lgUp} {
        padding-top: 210px;
    }
`;

export const MobileCard = styled(Card)`
    ${media.mobileOnly}
`;

export const MobileLogo = styled.img`
    margin: 0 auto;

    transform: translateX(10px);

    ${media.mobileOnly}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.mdUp} {
        align-items: flex-start;
    }

    ${media.lgUp} {
        flex-flow: row wrap;
        max-width: 530px;
    }
`;

export const Title = styled(PageTitle)`
    flex: 1 0 auto;
    order: 0;
    max-width: 350px;
    margin: 0 0 20px;

    ${media.mdUp} {
        max-width: 350px;

        font-size: 50px;
        line-height: 65px;
        text-align: left;
    }

    ${media.xlUp} {
        max-width: 100%;

        font-size: 70px;
        line-height: 85px;
    }
`;

export const UspWrapper = styled.div`
    display: flex;
    align-items: center;
    order: 3;
    margin: 0 0 10px;

    ${media.mdUp} {
        align-items: flex-start;
        order: unset;
    }

    ${media.lgUp} {
        margin: 0 0 0 40px;
    }
`;

export const OrderButton = styled(Button)`
    order: 4;

    ${media.mdUp} {
        order: unset;
        margin-bottom: 10px;
    }

    ${media.lgUp} {
        margin-bottom: 0;
    }
`;
