import styled from "styled-components";
import NextImage from "next/image";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    display: flex;
    align-items: center;
    margin-bottom: 45px;

    ${media.mdUp} {
        margin-bottom: 110px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 30px;

    font-size: 28px;
    line-height: 40px;

    ${media.mdUp} {
        font-size: 25px;
        line-height: 35px;
    }

    ${media.lgUp} {
        font-size: 40px;
        line-height: 50px;
    }

    ${media.xlUp} {
        margin: 0 0 45px;
    }
`;

export const Content = styled.div`
    width: 100%;

    ${media.mdUp} {
        position: relative;

        display: flex;
        flex: 2;
        flex-direction: column;
        padding: 30px 60px 30px 30px;

        background-color: var(--color-white);
        border-radius: var(--border-radius-medium) 0 0
            var(--border-radius-medium);

        &::after {
            position: absolute;
            top: -65px;
            right: -130px;
            z-index: 1;

            width: 200px;
            height: 211px;

            background: url("/images/illustrations/rocks.svg") top center
                no-repeat;

            content: "";
        }
    }

    ${media.lgUp} {
        padding: 40px 100px 50px 50px;
    }

    ${media.xlUp} {
        padding: 40px 150px 50px 50px;
    }
`;

export const ImageContainer = styled.div`
    position: relative;

    flex: 0 1 auto;
    width: 368px;
    height: 735px;

    ${media.lgUp} {
        width: 397px;
        height: 684px;
    }

    ${media.xlUp} {
        width: 480px;
        height: 650px;
    }
`;

export const Image = styled(NextImage)`
    border-bottom-right-radius: 100px;
`;
