import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Container, Shape1, Shape2, Shape3 } from "./BackgroundImages.styled";

function BackgroundImages() {
    const { mobileView } = useMediaQueryContext();

    if (mobileView) {
        return null;
    }

    return (
        <Container>
            <Shape1 />
            <Shape2 />
            <Shape3 />
        </Container>
    );
}

export default BackgroundImages;
