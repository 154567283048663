import Link from "next/link";
import PropTypes from "prop-types";
import NextImage from "next/image";

import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";
import IconChevronRight from "components/ui/icons/IconChevronRight";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveImages } from "helpers/page";

import {
    Container,
    ImageContainer,
    Content,
    Title,
    Subtitle,
    ListItem,
    Anchor,
    LinkImage,
    LinkText,
    List
} from "./Usage.styled";

const renderLinks = items => {
    return items.map((item, index) => {
        const { link, images } = item;

        if (!item?.link) {
            return null;
        }

        return (
            <ListItem key={`usage-${index}-${link.label}`}>
                <Link href={link.url} passHref prefetch={false}>
                    <Anchor>
                        {images?.[0]?.url && (
                            <LinkImage
                                src={images[0].url}
                                alt={images[0].alt ?? ""}
                                width={17}
                                height={17}
                                loading="lazy"
                            />
                        )}
                        <LinkText>{link.label}</LinkText>
                        <IconChevronRight
                            size={24}
                            fill="var(--color-primary)"
                        />
                    </Anchor>
                </Link>
            </ListItem>
        );
    });
};

function Usage({ title, images, sections }) {
    const { mobileView } = useMediaQueryContext();
    const { mobile, desktop } = resolveImages(images);

    return (
        <Container forwardedAs="section">
            <Content>
                <Title>{title}</Title>
                {sections.map((section, index) => (
                    <>
                        <Subtitle as={index === 0 ? "h3" : "h4"}>
                            {section.title}
                        </Subtitle>
                        {section.description && (
                            <ContentRenderer>
                                {section.description}
                            </ContentRenderer>
                        )}
                        {section.sections && (
                            <List>{renderLinks(section.sections)}</List>
                        )}
                    </>
                ))}
            </Content>
            <ImageContainer>
                {mobileView ? (
                    <NextImage
                        src={mobile?.url ?? "/images/usage-mobile.jpg"}
                        alt={mobile?.alt ?? "Sfeerafbeelding ontvangen"}
                        layout="responsive"
                        width={290}
                        height={190}
                        quality={100}
                    />
                ) : (
                    !mobileView && (
                        <NextImage
                            src={desktop?.url ?? "/images/usage-desktop.jpg"}
                            alt={desktop?.alt ?? "Sfeerafbeelding ontvangen"}
                            layout="responsive"
                            width={400}
                            height={690}
                            quality={100}
                        />
                    )
                )}
            </ImageContainer>
        </Container>
    );
}

Usage.propTypes = {
    title: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    ),
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                    images: PropTypes.arrayOf(
                        PropTypes.shape({
                            alt: PropTypes.string,
                            url: PropTypes.string
                        })
                    ),
                    link: PropTypes.shape({
                        label: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            )
        })
    )
};

Usage.defaultProps = {
    title: "Ontvangen?",
    images: [
        {
            url: "/images/usage-mobile.jpg",
            alt: "Sfeerafbeelding ontvangen"
        },
        {
            url: "/images/usage-desktop.jpg",
            alt: "Sfeerafbeelding ontvangen"
        }
    ],
    sections: [
        {
            title: "Heb je een Sauna & Wellness bon ontvangen?",
            description:
                "Gefeliciteerd! Je kunt heerlijk gaan genieten bij ruim 210 sauna’s, thermen of wellness centra door heel Nederland.",
            sections: [
                {
                    link: {
                        label: "Vind een spa",
                        url: "/resorts/"
                    }
                },
                {
                    link: {
                        label: "Check je saldo",
                        url: "/saldocheck/"
                    }
                }
            ]
        },
        {
            title: "Maak nu kans op verdubbeling van je saldo!",
            sections: [
                {
                    images: [
                        {
                            url: "/images/icon-doubler-blue.svg",
                            alt: "Verdubbele je saldo"
                        }
                    ],
                    link: {
                        label: "Verdubbel je saldo",
                        url: "/verdubbelaar/"
                    }
                }
            ]
        }
    ]
};

export default Usage;
