import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    padding: 0 0 35px;

    ${media.mdUp} {
        padding: 0;
    }
`;

export const Text = styled.p`
    margin: 0 0 15px;

    color: var(--color-gray-400);
    text-align: center;

    ${media.mdUp} {
        margin: 0;

        text-align: left;
    }
`;

export const Logos = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    margin: 0 auto;
    padding: 0;

    list-style-type: none;

    ${media.mdUp} {
        max-width: 75%;
        margin: 0;
    }

    ${media.lgUp} {
        max-width: initial;
    }
`;

export const Item = styled.li`
    flex: 0 1 auto;
`;

export const Logo = styled.img`
    max-width: 50px;
    height: auto;

    opacity: 0.5;
    filter: grayscale(100%);

    transition-timing-function: var(--animation-during-standard);
    transition-duration: var(--animation-duration-fast);
    transition-property: filter, opacity;

    @media (any-hover: hover) {
        :hover {
            opacity: 1;
            filter: grayscale(0%);
        }
    }

    ${media.lgUp} {
        max-width: initial;
        height: initial;
    }
`;
