import Link from "next/link";
import PropTypes from "prop-types";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveImages } from "helpers/page";

import IconChevronRight from "components/ui/icons/IconChevronRight";

import Partners from "./partners/Partners";

import {
    Container,
    TextContainer,
    Header,
    Title,
    Intro,
    Content,
    Subtitle,
    Text,
    Usps,
    Anchor,
    ButtonWide,
    ImageWrapper,
    Image
} from "./Business.styled";

function Business({ title, description, images, link, sections }) {
    const { mobileView } = useMediaQueryContext();
    const { mobile, desktop } = resolveImages(images);

    return (
        <Container forwardedAs="section">
            <TextContainer>
                <Header>
                    <Title>{title}</Title>
                    <Intro>{description}</Intro>
                    {!mobileView && link?.url && (
                        <Link href={link.url} passHref prefetch={false}>
                            <ButtonWide element="a">{link.label}</ButtonWide>
                        </Link>
                    )}
                </Header>
                {mobileView && (
                    <ImageWrapper>
                        <Image
                            src={
                                mobile?.url ??
                                "/images/business-gift-mobile.jpg"
                            }
                            alt={mobile?.alt ?? "Zakelijk - sfeerafbeelding"}
                            layout="fill"
                            objectFit="cover"
                            quality={75}
                        />
                    </ImageWrapper>
                )}
                <Content>
                    <Usps>
                        {sections?.map(
                            ({ title, description, link }, index) => (
                                <li key={`usp-${index}-${title}`}>
                                    <Subtitle>{title}</Subtitle>
                                    <Text>{description}</Text>
                                    {link?.url && (
                                        <Link
                                            href={link.url}
                                            passHref
                                            prefetch={false}
                                        >
                                            <Anchor>
                                                {link.label}
                                                <IconChevronRight
                                                    size={19}
                                                    fill="var(--color-primary)"
                                                />
                                            </Anchor>
                                        </Link>
                                    )}
                                </li>
                            )
                        )}
                    </Usps>
                    {mobileView && (
                        <Link href={link.url} passHref prefetch={false}>
                            <ButtonWide element="a">{link.label}</ButtonWide>
                        </Link>
                    )}
                    <Partners />
                </Content>
            </TextContainer>
            {!mobileView && (
                <ImageWrapper>
                    <Image
                        src={
                            desktop?.url ?? "/images/business-gift-desktop.jpg"
                        }
                        alt={desktop?.alt ?? "Zakelijk - sfeerafbeelding"}
                        layout="fill"
                        objectFit="cover"
                        quality={100}
                    />
                </ImageWrapper>
            )}
        </Container>
    );
}

Business.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    ),
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        })
    )
};

Business.defaultProps = {
    title: "Het perfecte zakelijke geschenk",
    description:
        "De Sauna & Wellness Cadeaukaart is een perfect geschenk voor je personeel of zakelijke relaties. De ontvanger kan een dagje ontspannen in één van de heerlijke sauna's en wellnesscentra door heel Nederland. De Sauna & Wellness cadeaukaart kan  besteed worden aan een dagje stomen in de sauna, beautybehandelingen, massages en nog veel meer behandelingen en faciliteiten die de acceptanten  bieden. Zo geeft u een cadeau waar iedereen zich mee in de watten kan laten leggen na een drukke werkweek!",
    link: {
        url: "/saunabon-bestellen/",
        label: "Direct naar bestellen"
    },
    images: [],
    sections: [
        {
            title: "Bestel op factuur",
            description:
                "Voor elke soort bestelling kan je makkelijk bestellen via factuur. Kies in het bestelformulier voor betaalmethode ‘Op factuur’.",
            link: {
                url: "/saunabon-bestellen/",
                label: "Direct naar bestellen"
            }
        },
        {
            title: "Goede persoonlijke service",
            description:
                "Op het gebied van zakelijke bestellingen is er vaak veel mogelijk, heb je bijvoorbeeld een goed idee over een verpakking? Let us know!",
            link: {
                url: "/zakelijk/",
                label: "Bekijk de mogelijkheden"
            }
        },
        {
            title: "Cadeauservice",
            description:
                "Geef je vaker een cadeaukaart aan je zakelijke relaties en/of personeel? Maak dan kennis met Cadeauservice!",
            link: {
                url: "/zakelijk/",
                label: "Neem contact op"
            }
        },
        {
            title: "Personalisatie",
            description:
                "Kies voor een speciale verpakking, laat je bedrijfslogo op de kaart drukken en/of laat een boodschap achter. De mogelijkheden zijn eindeloos.",
            link: {
                url: "/saunabon-bestellen/",
                label: "Bekijk de mogelijkheden"
            }
        }
    ]
};

export default Business;
