import NextImage from "next/image";

import styled from "styled-components";
import media from "constants/media";

import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 90px;

    ${media.mdUp} {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
    }

    ${media.lgUp} {
        margin-bottom: 170px;
    }
`;

export const ImageContainer = styled.div`
    position: relative;

    width: 94.38%;
    max-width: 500px;
    margin: 0 0 25px -45px;
    aspect-ratio: 15 / 11;

    ${media.mdUp} {
        width: 340px;
        max-width: unset;
        height: 380px;
        margin: 0;
        aspect-ratio: 48 / 35;
    }

    ${media.lgUp} {
        width: 400px;
        height: 350px;
    }

    ${media.xlUp} {
        width: 480px;
    }
`;

export const Image = styled(NextImage)`
    /* stylelint-disable-next-line */
    border-radius: 0 0 60px 0;

    ${media.mdUp} {
        border-radius: 0 0 0 100px;
    }
`;

export const Content = styled.div`
    ${media.mdUp} {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 40px;

        background-color: var(--color-white);
        border-radius: 0 var(--border-radius-medium) var(--border-radius-medium)
            0;
    }

    ${media.lgUp} {
        padding: 50px 70px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 10px;

    font-size: 25px;
    line-height: 35px;
`;

export const Description = styled(ContentRenderer)`
    margin: 0 0 20px;

    a {
        color: var(--color-primary);
        font-weight: var(--font-weight-semi-bold);
    }

    ${media.mdUp} {
        max-width: 550px;
        margin: 0 0 20px;
    }
`;

export const Badge = styled.div`
    position: absolute;
    top: -50px;
    right: -50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;

    color: var(--color-white);
    font-size: 18px;
    line-height: 22px;

    background-color: var(--color-purple-500);
    border-radius: 50%;
    transform: scale(0.92);

    img {
        width: 45px;
        height: 45px;
        margin: -10px 0 2px;
    }

    strong {
        font-weight: var(--font-weight-semi-bold);
    }

    ${media.mdUp} {
        top: -60px;
        right: -45px;

        transform: scale(0.75);
    }

    ${media.lgUp} {
        top: -30px;
        right: -55px;

        transform: scale(1);
    }
`;
