import PropTypes from "prop-types";
import Link from "next/link";

import ResellerLogos from "components/shared/resellerLogos/ResellerLogos";

import {
    Container,
    ImageContainer,
    Title,
    Description,
    ButtonRow,
    LogoSolo,
    StyledButton,
    Image,
    Content
} from "./SellingPoints.styled";

function SellingPoints({ title, description, link, image, logos }) {
    const logosMinusFirst =
        Array.isArray(logos) && logos.length > 1
            ? logos.slice(1, logos.length)
            : [];

    return (
        <Container forwardedAs="section">
            <Content>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <ButtonRow>
                    <Link href={link?.url} passHref prefetch={false}>
                        <StyledButton element="a" variant="outlined">
                            {link?.label}
                        </StyledButton>
                    </Link>
                    <LogoSolo
                        src={
                            logos?.[0]?.url ??
                            "/images/logos/sellingpoints/logo-cigo.svg"
                        }
                        alt={logos?.[0]?.alt ?? "Logo Cigo"}
                        width={77}
                        height={40}
                        loading="lazy"
                        grayscale
                        objectFit="contain"
                    />
                </ButtonRow>
                <ResellerLogos logos={logosMinusFirst} grayscale />
            </Content>
            <ImageContainer>
                <Image
                    src={image?.url ?? "/images/sellingpoints.jpg"}
                    alt={image?.alt ?? "Sfeerafbeelding"}
                    width={380}
                    height={380}
                    quality={75}
                    objectFit="contain"
                />
            </ImageContainer>
        </Container>
    );
}

SellingPoints.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }),
    logos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    )
};

SellingPoints.defaultProps = {
    title: "Verkooppunten",
    description:
        "De Sauna & Wellness cadeaukaart koop je gemakkelijk via deze website. Liever naar de winkel? Dat kan natuurlijk ook.",
    link: {
        url: "/verkooppunten/",
        label: "Vind een winkel in de buurt"
    },
    image: {
        url: "/images/sellingpoints.jpg",
        alt: "Sfeerafbeelding"
    },
    logos: undefined
};

export default SellingPoints;
