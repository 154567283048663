import styled from "styled-components";

import IconChevronRight from "components/ui/icons/IconChevronRight";

export const Chevron = styled(IconChevronRight)`
    transition: all var(--animation-duration-default) ease;
`;

export const Anchor = styled.a`
    display: flex;
    gap: 2px;
    align-items: center;

    color: var(--color-primary);
    font-size: 14px;
    line-height: 30px;

    transition: all var(--animation-duration-default) ease;

    @media (any-hover: hover) {
        :hover,
        :focus {
            color: var(--color-blue-300);

            ${Chevron} {
                transform: translateX(5px);

                fill: var(--color-blue-300);
            }
        }
    }
`;
