import styled from "styled-components";

import media from "constants/media";

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    order: 1;
    width: 100%;
    margin: 0 0 10px;

    ${media.mdUp} {
        order: unset;
        max-width: 350px;
        margin: 0;
    }

    ${media.lgUp} {
        width: auto;
        max-width: initial;
    }
`;
