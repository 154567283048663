import styled from "styled-components";
import NextImage from "next/image";

import media from "constants/media";

import Button from "components/ui/button/Button";
import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

import { Logo } from "components/shared/resellerLogos/ResellerLogos.styled";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 35px;
    padding: 40px 10px 10px;

    background-color: var(--color-white);

    ${media.mdUp} {
        flex-direction: row-reverse;
        align-items: flex-start;
        margin: 0 auto 80px;
        padding: 0 10px;

        background-color: transparent;
    }

    ${media.lgUp} {
        margin: 0 auto 120px;
    }
`;

export const Content = styled.div`
    ${media.mdUp} {
        margin: 0 0 0 -100px;
        padding: 50px 50px 50px 150px;

        background-color: var(--color-white);
        border-radius: var(--border-radius-medium);
    }

    ${media.lgUp} {
        width: 800px;
    }

    ${media.xlUp} {
        padding: 50px 100px 50px 220px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 20px;

    font-size: 26px;
    line-height: 35px;

    ${media.mdUp} {
        margin: 0 0 10px;

        font-size: 30px;
        line-height: 40px;
    }

    ${media.lgUp} {
        font-size: 40px;
        line-height: 55px;
    }
`;

export const Description = styled(ContentRenderer)`
    margin: 0 0 10px;

    ${media.mdUp} {
        margin: 0 0 25px;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    gap: 0 5px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    ${media.mdUp} {
        gap: 0 20px;
        margin-bottom: 30px;
    }
`;

export const StyledButton = styled(Button)`
    flex: 0 1 auto;
    padding: 9px 14px;

    ${media.mdUp} {
        padding: 9px 18px;
    }
`;

export const ImageContainer = styled.div`
    align-self: flex-end;
    width: 260px;
    height: 260px;
    margin: 0 -10px 35px 0;

    ${media.mdUp} {
        position: relative;

        flex: 0 0 auto;
        width: 300px;
        height: 300px;

        margin-top: 60px;

        ::before {
            position: absolute;
            top: -60px;
            left: -95px;
            z-index: 1;

            width: 124px;
            height: 492px;

            background: url("/images/illustrations/plant.svg") top left
                no-repeat;

            content: "";
        }
    }

    ${media.lgUp} {
        width: 380px;
        height: 380px;
        margin-top: 120px;
    }

    ${media.xlUp} {
        margin-top: 140px;
    }
`;

export const Image = styled(NextImage)`
    ${media.mdUp} {
        border-top-left-radius: 100px;
    }
`;

export const LogoSolo = styled(Logo)`
    flex: 1;
    max-width: 62px;
    height: 37px;

    ${media.mdUp} {
        width: initial;
        height: initial;
    }
`;
