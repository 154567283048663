import styled from "styled-components";
import Image from "next/image";

import media from "constants/media";

export const Card = styled.div`
    position: relative;

    width: 100%;
    max-width: ${({ width }) => width}px;
    aspect-ratio: var(--aspect-ratio-card);

    ${media.mdUp} {
        :before {
            position: absolute;
            top: 35%;
            left: 9%;

            width: 83.3%;
            height: 72%;

            background: url(${({ image }) => image});
            border-radius: calc(${({ width }) => width}px / 15);
            filter: blur(50px);

            content: "";
        }
    }

    ${media.lgUp} {
        width: ${({ width }) => width}px;
    }
`;

export const CardImage = styled(Image)`
    border-radius: calc(${({ width }) => width}px / 15);
    aspect-ratio: var(--aspect-ratio-card);
`;
