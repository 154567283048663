import PropTypes from "prop-types";

import landingPagePropTypes from "types/LandingPage.propTypes";
import { resolveApiData } from "helpers/page";

import SeoText from "components/shared/seoText/SeoText";
import SeoAndDoubler from "components/shared/seoAndDoubler/SeoAndDoubler";
import MerchantLogos from "components/shared/merchantLogos/MerchantLogos";
import Locations from "components/shared/locations/Locations";

import Hero from "./hero/Hero";
import Usps from "./usps/Usps";
import PrivateSaunaBanner from "./privateSaunaBanner/PrivateSaunaBanner";
import Steps from "./steps/Steps";
import Usage from "./usage/Usage";
import Business from "./business/Business";
import Merchants from "./merchants/Merchants";
import SellingPoints from "./sellingPoints/SellingPoints";
import BackgroundImages from "./backgroundImages/BackgroundImages";

import { Wrapper } from "./LandingPage.styled";

function resolveBlockData(block) {
    return block && block.length > 0 ? block[0] : undefined;
}

function LandingPage({ data, loading, isChristmas }) {
    const {
        hero,
        seo: { first, second, third },
        steps,
        usps,
        received,
        sections,
        blocks,
        merchants,
        popularMerchants
    } = data || {};
    const stepsContent = resolveBlockData(steps);
    const usageContent = resolveBlockData(received);
    const logosTop = blocks?.[0] || undefined;
    const privateSauna = blocks?.[1] || undefined;
    const businessContent = blocks?.[2] || undefined;
    const locations = blocks?.[3] || undefined;
    const popularMerchantsBlock = blocks?.[4] || undefined;
    const resellers = blocks?.[5] || undefined;
    const doublerBlock = blocks?.[6] || undefined;
    const logosBottom = blocks?.[7] || undefined;

    return (
        <Wrapper>
            <Hero
                title={hero?.title}
                usps={hero?.usps}
                cardImage={hero?.image}
                backgroundImages={hero?.background}
                isChristmas={isChristmas}
                loading={loading}
            />
            <MerchantLogos
                title={logosTop?.title}
                link={logosTop?.link ?? undefined}
                merchants={merchants?.edges}
                mobileBackgroundColor="var(--color-white)"
            />
            <Usps items={usps} />
            {first &&
                resolveApiData(first, "title") &&
                resolveApiData(first, "description") && (
                    <SeoText
                        title={first.title}
                        text={first.description}
                        position="top"
                        clamp
                    />
                )}
            <PrivateSaunaBanner
                title={privateSauna?.title}
                description={privateSauna?.description}
                link={resolveApiData(privateSauna, "link")}
                image={privateSauna?.images?.[0]}
            />
            <Steps
                title={stepsContent?.title}
                image={stepsContent?.images?.[0]}
                items={stepsContent?.sections}
            />
            {sections?.usage && (
                <Usage
                    title={usageContent?.title}
                    description={usageContent?.description}
                    images={usageContent?.images}
                    sections={usageContent?.sections}
                />
            )}
            {sections?.business && (
                <Business
                    title={businessContent?.title}
                    description={businessContent?.description}
                    images={businessContent?.images}
                    link={resolveApiData(businessContent, "link")}
                    sections={businessContent?.sections}
                />
            )}
            {sections.restaurants && (
                <>
                    <Locations
                        title={locations?.title}
                        description={locations?.description}
                        image={locations?.images?.[0]}
                        link={resolveApiData(locations, "link")}
                        merchants={popularMerchants?.edges}
                    />
                    <Merchants
                        title={popularMerchantsBlock?.title}
                        merchants={popularMerchants?.edges}
                    />
                </>
            )}
            {sections?.resellers && (
                <SellingPoints
                    title={resellers?.title}
                    description={resellers?.description}
                    link={resolveApiData(resellers, "link")}
                    image={resellers?.sections?.images?.[0]}
                    logos={resellers?.images}
                />
            )}
            {(second || sections?.doubler) && (
                <SeoAndDoubler
                    title={second?.title}
                    description={second?.description}
                    doubler={sections?.doubler}
                    doublerContent={doublerBlock}
                />
            )}
            <MerchantLogos
                title={logosBottom?.title}
                link={logosBottom?.link ?? undefined}
                merchants={merchants?.edges}
            />
            {third &&
                resolveApiData(third, "title") &&
                resolveApiData(third, "description") && (
                    <SeoText
                        title={third.title}
                        text={third.description}
                        position="bottom"
                        clamp
                    />
                )}
            <BackgroundImages />
        </Wrapper>
    );
}

LandingPage.propTypes = {
    data: landingPagePropTypes,
    loading: PropTypes.bool,
    isChristmas: PropTypes.bool
};

LandingPage.defaultProps = {
    data: {
        hero: {},
        seo: {
            first: {
                title: "Geniet van Sauna of Wellness door heel Nederland",
                description:
                    "De Nationale Sauna & Wellnesss Cadeaukaart kunt u bij 85+ prachtige locaties door heel Nederland besteden. Zo hoeft u nooit ver te reizen voor een bezoek aan een sauna of wellness resort. Maar het kan ook dat u met uw saunabon juist een nieuwe sauna wilt ontdekken. Zo kunt u voor een ontspannen dagje de Thermen Soesterberg bezoeken. In deze prachtige grote sauna komt u weer helemaal tot rust in een natuurlijke omgeving. Maar u bent ook verzekerd van nieuwe energie na een dag in Thermen Bussloo. Thermen Bussloo is prachtig vormgegeven en heeft ontzettend veel sauna's, baden, massages en beautybehandelingen waar u gebruik van kunt maken. Eeen bezoek boj Fort Resort Beemster zuit u niet snal vergeten: dit oude fort is omgebouwd tot een resort met geweldige faciliteiten."
            },
            second: {
                title: "Je Sauna & Wellness bon besteden",
                description:
                    "De Nationale Sauna & Wellness Cadeaukaart kunt u bij 85+ prachtige locaties door heel Nederland besteden. Zo hoeft u nooit ver te reizen voor een bezoek aan een sauna of wellness resort. Maar het kan ook dat u met uw saunabon juist een nieuwe sauna wilt ontdekken. Zo kunt u voor een ontspannen dagje de Thermen Soesterberg bezoeken. In deze prachtige grote sauna komt u weer helemaal tot rust in een natuurlijke omgeving. Maar u bent ook verzekerd van nieuwe energie na een dag in Thermen Bussloo. Thermen Bussloo is prachtig vormgegeven en heeft ontzettend veel sauna's, baden, massages en beautybehandelingen waar u gebruik van kunt maken. Een bezoek bij Fort Resort Beemster zult u niet snel vergeten: dit oude fort is omgebouwd tot een resort met geweldige faciliteiten."
            },
            third: {
                title: "Geniet van Sauna of Wellness door heel Nederland",
                description:
                    "De Nationale Sauna & Wellness Cadeaukaart kunt u bij 85+ prachtige locaties door heel Nederland besteden. Zo hoeft u nooit ver te reizen voor een bezoek aan een sauna of wellness resort. Maar het kan ook dat u met uw saunabon juist een nieuwe sauna wilt ontdekken. Zo kunt u voor een ontspannen dagje de Thermen Soesterberg bezoeken. In deze prachtige grote sauna komt u weer helemaal tot rust in een natuurlijke omgeving. Maar u bent ook verzekerd van nieuwe energie na een dag in Thermen Bussloo. Thermen Bussloo is prachtig vormgegeven en heeft ontzettend veel sauna's, baden, massages en beautybehandelingen waar u gebruik van kunt maken. Een bezoek bij Fort Resort Beemster zult u niet snel vergeten: dit oude fort is omgebouwd tot een resort met geweldige faciliteiten."
            }
        },
        steps: [],
        received: [],
        merchants: {
            edges: []
        },
        popularMerchants: {
            edges: []
        },
        blocks: [],
        sections: {
            restaurants: true,
            business: true,
            resellers: true,
            usage: true,
            doubler: true
        }
    },
    loading: false,
    isChristmas: process.env.featureFlags.christmas
};

export default LandingPage;
