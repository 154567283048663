import PropTypes from "prop-types";
import Link from "next/link";
import Head from "next/head";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import UspTicker from "components/shared/uspTicker/UspTicker";

import Card from "./card/Card";

import {
    BackgroundImage,
    Container,
    Content,
    MobileLogo,
    MobileCard,
    Title,
    UspWrapper,
    OrderButton
} from "./Hero.styled";

function resolveBackground(images, isChristmas) {
    let mobileImage = "/images/shapes/lp-hero-bg-mobile.svg";
    let desktopImage = "/images/shapes/lp-hero-bg-desktop.svg";

    if (isChristmas) {
        return {
            mobileImage: "/images/shapes/lp-hero-bg-mobile-xmas.svg",
            desktopImage: "/images/shapes/lp-hero-bg-desktop-xmas.svg"
        };
    }

    if (images.length === 1) {
        return {
            mobileImage: images[0].url,
            desktopImage: images[0].url
        };
    }

    if (images.length > 1) {
        images.forEach(image => {
            if (image.type === "MOBILE") {
                mobileImage = image.url;
            }
            if (image.type === "DESKTOP") {
                desktopImage = image.url;
            }
            if (image.type === "ALL") {
                mobileImage = image.url;
                desktopImage = image.url;
            }
        });
    }

    return { mobileImage, desktopImage };
}

function Hero({
    title,
    usps,
    cardImage,
    backgroundImages,
    loading,
    isChristmas
}) {
    const { mobileView } = useMediaQueryContext();
    const { mobileImage, desktopImage } = resolveBackground(
        backgroundImages,
        isChristmas
    );
    const pageIcon = isChristmas
        ? "/icons/pages/santa.svg"
        : "/images/eyes.svg";

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    // eslint-disable-next-line react/no-unknown-property
                    fetchpriority="high"
                    as="image"
                    href={mobileImage}
                    type="image/svg+xml"
                />
                <link
                    rel="preload"
                    // eslint-disable-next-line react/no-unknown-property
                    fetchpriority="high"
                    as="image"
                    href="/images/logos/logo-sauna-wellness-color.svg"
                    type="image/svg+xml"
                />
            </Head>
            <BackgroundImage imgMobile={mobileImage} imgDesktop={desktopImage}>
                <Container>
                    <MobileLogo
                        src="/images/logos/logo-sauna-wellness-color.svg"
                        alt="Logo Sauna & Wellness Cadeaukaart"
                        height={29}
                        width={160}
                        aria-hidden="true"
                    />
                    <Content>
                        <Title pageIcon={pageIcon} type="large">
                            {title}
                        </Title>
                        <MobileCard
                            url={cardImage?.url}
                            alt={cardImage?.alt}
                            loading={loading}
                        />
                        <Link
                            href="/saunabon-bestellen/"
                            passHref
                            prefetch={false}
                        >
                            <OrderButton element="a">
                                Direct bestellen
                            </OrderButton>
                        </Link>
                        <UspWrapper>
                            <UspTicker usps={usps} type="smaller" />
                        </UspWrapper>
                    </Content>
                    {!mobileView && (
                        <Card
                            url={cardImage?.url}
                            alt={cardImage?.alt}
                            loading={loading}
                        />
                    )}
                </Container>
            </BackgroundImage>
        </>
    );
}

Hero.propTypes = {
    title: PropTypes.string,
    backgroundImages: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            type: PropTypes.oneOf(["MOBILE", "DESKTOP", "ALL"])
        })
    ),
    cardImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
    }),
    usps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string
        })
    ),
    loading: PropTypes.bool,
    isChristmas: PropTypes.bool
};

Hero.defaultProps = {
    title: "Geef een dag ontspanning cadeau",
    usps: [
        { title: "Te bestellen van € 5,- tot € 150,-" },
        { title: "De 210 mooiste locaties van Nederland" },
        { title: "Voor 17:00 besteld = vandaag verstuurd" },
        { title: "Bedrijven bestellen gemakkelijk op factuur" }
    ],
    backgroundImages: [
        {
            url: "/images/shapes/lp-hero-bg-desktop.svg",
            type: "DESKTOP"
        },
        {
            url: "/images/shapes/lp-hero-bg-mobile.svg",
            type: "MOBILE"
        }
    ],
    cardImage: {
        url: "/images/card.webp",
        alt: "Nationale Sauna & Wellness cadeaukaart"
    },
    loading: false,
    isChristmas: process.env.featureFlags.christmas
};

export default Hero;
