import styled, { css } from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 80px;
    padding: 0;

    ${media.mdUp} {
        flex-direction: row-reverse;
        align-items: center;
        padding: 0 10px;
    }

    ${media.lgUp} {
        margin-bottom: 160px;
    }

    ${media.xlUp} {
        padding-left: 210px;
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    z-index: 1;

    width: calc(100% - 30px);
    max-width: 350px;
    margin: 0 0 -20px;

    ${media.mdUp} {
        width: 40.82%;
        max-width: unset;
        margin: 0 -40px 0 0;
        aspect-ratio: 40 / 69;

        img {
            z-index: 2;

            border-radius: 0 100px 0 0;
        }
    }

    ${media.lgUp} {
        margin: 0 -20px 0 0;

        ::before {
            position: absolute;
            top: 110px;
            right: -80px;
            z-index: 1;

            display: block;
            width: 445px;
            height: 655px;

            background-image: url("/images/shapes/bg-shape-have-you.svg");
            background-repeat: no-repeat;
            background-position: top;
            background-size: 100%;

            content: "";
        }
    }
`;

export const Content = styled.div`
    padding: 40px 10px 20px;

    background-color: var(--color-white);

    ${media.mdUp} {
        flex: 1;
        padding: 40px 50px 40px 80px;

        border-radius: 0 var(--border-radius-medium) var(--border-radius-medium)
            0;
    }

    ${media.lgUp} {
        padding: 50px 110px 50px 120px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 20px;

    font-size: 25px;
    line-height: 35px;

    ${media.mobileHide}

    ${media.lgUp} {
        margin: 0 0 30px;

        font-size: 40px;
        line-height: 50px;
    }
`;

export const Subtitle = styled.h3`
    padding: 0;

    ${({ as }) =>
        as === "h3" &&
        css`
            margin: 0 0 5px;

            font-size: 20px;

            ${media.mdUp} {
                margin: 0 0 10px;

                font-size: 18px;
                line-height: 25px;
            }

            ${media.lgUp} {
                margin: 0 0 25px;

                font-size: 20px;
                line-height: 30px;
            }
        `}

    ${({ as }) =>
        as === "h4" &&
        css`
            margin: 0 0 5px;

            font-size: 16px;
            line-height: 25px;

            ${media.mdUp} {
                margin: 0 0 15px;
            }
        `}
`;

export const Text = styled.div`
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    p {
        margin: 0 0 10px;
    }
`;

export const List = styled.ul`
    margin: 0 0 50px;
    padding: 0;

    list-style: none;

    &:last-of-type {
        margin: 0;
    }

    ${media.mdUp} {
        margin: 0 0 20px;
    }

    ${media.lgUp} {
        margin: 0 0 50px;
    }
`;

export const ListItem = styled.li`
    position: relative;

    display: flex;
    width: 100%;
`;

export const Anchor = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 8px 0;

    color: var(--color-primary);
    font-weight: var(--font-weight-normal);
    font-size: 14px;
    line-height: 30px;

    transition: all var(--animation-duration-default) ease;

    svg {
        transition: all var(--animation-duration-default) ease;
    }

    :hover,
    :focus {
        svg {
            transform: translateX(5px);
        }
    }
`;

export const LinkImage = styled.img`
    margin-right: 10px;
`;

export const LinkText = styled.span`
    flex: 0 1 100%;
`;
