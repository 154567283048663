import styled, { css } from "styled-components";

import media from "constants/media";

import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled.div`
    padding: 0 10px;

    ${({ position }) =>
        position === "top" &&
        css`
            margin: 40px auto 110px;
        `}

    ${({ position }) =>
        position === "bottom" &&
        css`
            margin: 0 auto 65px;
        `}

    ${media.mdUp} {
        max-width: 780px;
        padding: 0;

        ${({ position }) =>
            position === "top" &&
            css`
                margin: 75px auto;
            `}

        ${({ position }) =>
            position === "bottom" &&
            css`
                margin: 0 auto 190px;
            `}

        ${({ clamp }) =>
            clamp &&
            css`
                text-align: center;
            `}
    }
`;

export const Title = styled.h2`
    margin: 0 auto 20px;

    font-size: 26px;
    line-height: 35px;

    ${media.mdUp} {
        font-size: 30px;
        line-height: 40px;
    }
`;

export const Content = styled(ContentRenderer)`
    margin: 0 auto;
`;
