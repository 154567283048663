import styled from "styled-components";
import NextImage from "next/image";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";
import Button from "components/ui/button/Button";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    ${media.mdUp} {
        flex-direction: row-reverse;
        margin-bottom: 150px;
    }
`;

export const TextContainer = styled.div`
    margin: 0 -10px;
    padding: 0 10px;

    ${media.mdUp} {
        max-width: 70%;
        margin: 0;
        padding: 25px 0 0;
    }
`;

export const Header = styled.div`
    ${media.mdUp} {
        padding: 0 0 40px 40px;
    }

    ${media.lgUp} {
        padding: 0 0 60px 105px;
    }

    ${media.xlUp} {
        max-width: 700px;
        padding: 0 0 90px 120px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 10px;

    font-size: 26px;
    line-height: 35px;

    ${media.mdUp} {
        margin: 0 0 15px;

        font-size: 30px;
        line-height: 40px;
    }
`;

export const Intro = styled(ContentRenderer)`
    margin: 0 0 20px;

    ${media.mdUp} {
        margin: 0 0 15px;
    }
`;

export const ButtonWide = styled(Button)`
    width: 100%;
    margin-bottom: 25px;

    ${media.mdUp} {
        width: auto;
        margin-bottom: 0;
        padding-right: 50px;
        padding-left: 50px;
    }
`;

export const Content = styled.div`
    position: relative;
    z-index: 1;

    margin: -150px -10px 0;
    padding: 190px 10px 0;

    background-color: var(--color-white);

    ${media.mdUp} {
        z-index: 0;

        margin: 0 0 0 -150px;
        padding: 40px 40px 30px 190px;

        border-radius: var(--border-radius-medium);
    }

    ${media.lgUp} {
        margin: 0 0 0 -165px;
        padding: 50px 85px 10px 270px;
    }

    ${media.xlUp} {
        min-height: 855px;
        margin: 0 0 0 -200px;
        padding: 50px 100px 10px 320px;
    }
`;

export const Usps = styled.ul`
    display: grid;
    gap: 25px 0;
    margin: 0;
    padding: 0 0 25px;

    list-style: none;

    ${media.mdUp} {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 20px;
        padding: 0 0 40px;
    }

    ${media.xlUp} {
        gap: 75px 20px;
        padding: 0 0 55px;
    }
`;

export const Subtitle = styled.h3`
    margin: 0 0 10px;

    font-size: 22px;
    line-height: 30px;

    ${media.mdUp} {
        margin: 0 0 5px;

        font-size: 16px;
        line-height: 25px;
    }

    ${media.lgUp} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const Text = styled(ContentRenderer)`
    margin: 0 0 5px;
`;

export const Anchor = styled.a`
    color: var(--color-primary);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    svg {
        margin-left: 2px;

        transition: transform var(--animation-duration-default) ease;
    }

    @media (any-hover: hover) {
        :hover,
        :focus {
            svg {
                transform: translateX(5px);
            }
        }
    }
`;

export const ImageWrapper = styled.div`
    position: relative;
    z-index: 2;

    width: 225px;
    height: 320px;
    margin-left: -40px;

    :after {
        position: absolute;
        bottom: -20px;
        left: 50px;
        z-index: -1;

        display: block;
        width: 280px;
        height: 195px;

        background: url("/images/shapes/blob1.svg") center no-repeat;

        content: "";
    }

    ${media.mdUp} {
        z-index: 1;

        flex: 1 0 auto;
        width: 365px;
        height: 1065px;
        margin-left: -125px;

        :after {
            bottom: -130px;
            left: -280px;

            width: 607px;
            height: 427px;
        }
    }

    ${media.lgUp} {
        width: 475px;
        height: 1002px;
        margin-left: -165px;

        :after {
            left: -155px;

            width: 600px;
            height: 414px;
        }
    }

    ${media.xlUp} {
        width: 505px;
        height: 1065px;
        margin-left: -125px;
    }
`;

export const Image = styled(NextImage)`
    margin: 0 -10px;

    ${media.mdUp} {
        border-radius: 0 100px 0 0;
    }
`;
