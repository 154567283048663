import PropTypes from "prop-types";

import TiltCard from "components/shared/tiltCard/TiltCard";

import { CardContainer } from "./Card.styled";

function Card({ className, loading, url, alt, ...props }) {
    return (
        <CardContainer className={className} {...props}>
            <TiltCard
                image={url}
                alt={alt}
                widthDesktop={482}
                widthMobile={220}
                priority
                loading={loading}
            />
        </CardContainer>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    alt: PropTypes.string,
    loading: PropTypes.bool
};

Card.defaultProps = {
    className: "",
    url: "/images/card.webp",
    alt: "Nationale Sauna & Wellness cadeaukaart",
    loading: false
};

export default Card;
