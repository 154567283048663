import PropTypes from "prop-types";
import { useRef, useMemo } from "react";
import VanillaTilt from "vanilla-tilt";
import useMedia from "use-media";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import SkeletonCard from "./skeletonCard/SkeletonCard";
import { Card, CardImage } from "./TiltCard.styled";

const vanillaTiltOptions = {
    reverse: true,
    max: 35,
    startX: -20,
    startY: 20,
    scale: 1,
    reset: true,
    easing: "cubic-bezier(0.090, 0.900, 0.955, 0.505",
    glare: true,
    "max-glare": 0,
    "glare-prerender": false,
    "full-page-listening": true,
    gyroscope: true,
    gyroscopeMinAngleX: -45,
    gyroscopeMaxAngleX: 45,
    gyroscopeMinAngleY: -45,
    gyroscopeMaxAngleY: 45
};

function TiltCard({
    className,
    loading,
    error,
    image,
    alt,
    priority,
    widthMobile,
    widthDesktop
}) {
    const ref = useRef();
    const isTouchDevice = useMedia("(pointer: coarse)", true);
    const { mobileView } = useMediaQueryContext();
    const width = mobileView && widthMobile ? widthMobile : widthDesktop;
    const height = Math.round(width * 0.648);

    useMemo(() => {
        const element = ref?.current;

        if (element && !isTouchDevice && !mobileView) {
            VanillaTilt.init(element, vanillaTiltOptions);
        }

        return () => {
            if (element?.vanillaTilt) {
                element.vanillaTilt.destroy();
            }
        };
    }, [ref, isTouchDevice, mobileView]);

    if (loading && !error) {
        return <SkeletonCard width={width} />;
    }

    return (
        <Card ref={ref} className={className} image={image} width={width}>
            <CardImage
                src={image}
                alt={alt}
                layout="responsive"
                quality={priority ? 75 : 100}
                priority={priority}
                width={width}
                height={height}
                sizes={`
                    (max-width: 767px) ${widthMobile}px,
                    ${widthDesktop}px
                `}
            />
        </Card>
    );
}

TiltCard.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    image: PropTypes.string,
    alt: PropTypes.string,
    widthMobile: PropTypes.number,
    widthDesktop: PropTypes.number,
    priority: PropTypes.bool
};

TiltCard.defaultProps = {
    className: "",
    loading: false,
    error: false,
    image: "/images/card.webp",
    alt: "Sauna & Wellness kaart",
    widthMobile: undefined,
    widthDesktop: 482,
    priority: false
};

export default TiltCard;
