import PropTypes from "prop-types";

import MerchantSlider from "components/shared/merchantSlider/MerchantSlider";
import { MerchantSliderItemApiPropTypes } from "types/MerchantSliderItem.propTypes";

import { Wrapper } from "./Merchants.styled";

function Merchants({ title, merchants }) {
    return (
        <Wrapper forwardedAs="section">
            <MerchantSlider
                title={title}
                merchants={merchants}
                filter={{ promoted: true }}
                limit={9}
            />
        </Wrapper>
    );
}

Merchants.propTypes = {
    title: PropTypes.string,
    merchants: PropTypes.arrayOf(MerchantSliderItemApiPropTypes)
};

Merchants.defaultProps = {
    title: "Meest populaire sauna’s & wellness centra",
    merchants: []
};

export default Merchants;
