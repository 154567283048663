import styled from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    margin-bottom: 50px;

    ${media.mdUp} {
        margin-bottom: 70px;
    }
`;

export const Title = styled.h2`
    margin: 0;

    font-size: 16px;
    line-height: 25px;

    ${media.mdUp} {
        margin: 0 0 5px;
    }
`;

export const LinkItems = styled.ul`
    display: flex;
    flex-flow: column wrap;
    gap: 20px 0;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    ${media.mdUp} {
        flex-flow: row wrap;
        gap: 20px;
        justify-content: space-between;
    }

    ${media.lgUp} {
        flex-flow: row nowrap;
        gap: 0 10px;
    }
`;

export const LinkItem = styled.li`
    width: 100%;
    padding: 0;

    ${media.mdUp} {
        flex: 1 0 calc(50% - 10px);
    }

    ${media.lgUp} {
        flex: 1 1 calc(25% - 10px);
    }
`;
