import styled, { css } from "styled-components";

import media from "constants/media";

export const UspTickerStyled = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 300px;
    height: 50px;
    padding: 10px 0;
    overflow: hidden;

    ${media.mdUp} {
        align-items: flex-start;
    }
`;

export const Text = styled.p`
    margin: 0;

    color: var(
        --color-${({ type }) => (type === "inverted" ? "white" : "gray-300")}
    );
    font-weight: var(--font-weight-normal);
    font-size: 12px;
    line-height: 30px;
    white-space: nowrap;
    text-align: left;

    ${({ type }) =>
        type === "smaller" &&
        css`
            svg {
                margin-right: 5px;
            }
        `}

    /* prettier-ignore */
    transform: translateY(${({ tickerChange }) =>
        tickerChange ? "-30px" : "0"});

    transition: ${({ tickerChange }) =>
        tickerChange ? "0.4s all ease-in-out" : "none"};
`;
