import PropTypes from "prop-types";

import ClampedText from "components/ui/clampedText/ClampedText";

import { Container, Title, Content } from "./SeoText.styled";

function SeoText({ title, text, clamp, position }) {
    return (
        <section>
            <Container position={position} clamp={clamp}>
                {clamp && <ClampedText title={title} text={text} />}
                {!clamp && (
                    <>
                        <Title>{title}</Title>
                        <Content>{text}</Content>
                    </>
                )}
            </Container>
        </section>
    );
}

SeoText.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    clamp: PropTypes.bool,
    position: PropTypes.oneOf(["top", "bottom"])
};

SeoText.defaultProps = {
    clamp: false,
    position: "top"
};

export default SeoText;
