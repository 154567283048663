import styled, { css } from "styled-components";
import media from "constants/media";

export const Logos = styled.ul`
    display: flex;
    flex-flow: row wrap;
    gap: 0 10px;
    justify-content: space-around;
    margin: 0;
    padding: 0;

    list-style-type: none;

    ${media.mdUp} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 40px;
    }
`;

export const Item = styled.li`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${media.smUp} {
        justify-content: flex-start;
    }
`;

export const Logo = styled.img`
    max-height: 65px;

    ${({ grayscale }) =>
        grayscale &&
        css`
            opacity: 0.5;
            filter: grayscale(100%);

            transition-timing-function: var(--animation-easing-standard);
            transition-duration: var(--animation-duration-fast);
            transition-property: filter, opacity;

            @media (any-hover: hover) {
                :hover,
                :focus {
                    opacity: 1;
                    filter: grayscale(0%);
                }
            }
        `}

    ${media.mdUp} {
        width: 100%;
        max-height: unset;
    }
`;
