import Link from "next/link";

import Button from "components/ui/button/Button";

import PropTypes from "prop-types";
import {
    Container,
    ImageContainer,
    Image,
    Badge,
    Content,
    Title,
    Description
} from "./PrivateSaunaBanner.styled";

function PrivateSaunaBanner({ title, description, link, image }) {
    const bannerImage = image ?? {
        url: "/images/private-sauna/hero-image-desktop.jpg",
        alt: "Naar een privé sauna met Sauna & Wellness"
    };

    return (
        <Container forwardedAs="section">
            <Content>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <Link href={link?.url} passHref prefetch={false}>
                    <Button element="a">{link?.label}</Button>
                </Link>
            </Content>
            <ImageContainer>
                <Image
                    src={bannerImage?.url}
                    alt={bannerImage?.alt}
                    layout="fill"
                    quality={75}
                    objectFit="cover"
                />
                <Badge>
                    <img
                        src="/images/private-sauna/private-sauna-logo-white.svg"
                        alt="Privé Sauna Logo"
                        width={35}
                        height={35}
                        loading="lazy"
                    />
                    <strong>exclusief</strong>
                    <span>genieten</span>
                </Badge>
            </ImageContainer>
        </Container>
    );
}

PrivateSaunaBanner.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    })
};

PrivateSaunaBanner.defaultProps = {
    title: "Naar een privé sauna met Sauna & Wellness",
    description: `Met de Sauna & Wellness Cadeaukaart kun je vanaf nu ook terecht bij alle aangesloten privé sauna’s van&nbsp;<a href="https://www.privesauna.nl" target="_blank" rel="noreferrer noopener">privesauna.nl</a>!`,
    link: {
        url: "/privesauna",
        label: "Ontdek de privé sauna’s"
    },
    image: {
        url: "/images/private-sauna/hero-image-desktop.jpg",
        alt: "Naar een privé sauna met Sauna & Wellness"
    }
};

export default PrivateSaunaBanner;
