import styled from "styled-components";

import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
`;

export const Shape1 = styled.div`
    position: absolute;
    top: 620px;
    left: -470px;

    width: 546px;
    height: 1687px;

    background: url("/images/shapes/lp-bg-shape-1.svg") top left no-repeat;
`;

export const Shape2 = styled.div`
    position: absolute;
    top: 3650px;
    right: -750px;

    width: 575px;
    height: 604px;

    background: url("/images/shapes/lp-bg-shape-2.svg") top left no-repeat;
`;

export const Shape3 = styled.div`
    position: absolute;
    top: 5950px;
    right: -635px;

    width: 470px;
    height: 1786px;

    background: url("/images/shapes/lp-bg-shape-3.svg") top left no-repeat;
`;
